import { unstable_useControl as useControl, type FieldMetadata } from "@conform-to/react";
import { useRef, type ElementRef, ComponentProps } from "react";
import {
  SelectTrigger,
  Select,
  SelectValue,
  SelectContent,
  SelectItem,
} from "#app/components/ui/select";

export const SelectConform = ({
  meta,
  items,
  placeholder,
  onItemSelected, // Callback untuk item yang dipilih
  ...props
}: {
  meta: FieldMetadata<string>;
  items: Array<{ name: string; value: string }> | null;
  placeholder: string;
} & ComponentProps<typeof Select>) => {
  const selectRef = useRef<ElementRef<typeof SelectTrigger>>(null);
  const control = useControl(meta);

  const handleValueChange = (value: string | string[]) => {
    if (onItemSelected) {
      onItemSelected(value);
    }
    props.onValueChange?.(value);
    control.change(value);
  };

  return (
    <>
      <select
        name={meta.name}
        defaultValue={meta.initialValue ?? ""}
        className="sr-only"
        ref={control.register}
        aria-hidden
        tabIndex={-1}
        onFocus={() => {
          selectRef.current?.focus();
        }}
      >
        <option value="" />
        {items?.map((option) => (
          <option key={option.value} value={option.value} />
        ))}
      </select>

      <Select
        {...props}
        value={control.value ?? ""}
        onValueChange={handleValueChange}
        onOpenChange={(open) => {
          if (!open) {
            control.blur();
          }
        }}
      >
        <SelectTrigger aria-invalid={props["aria-invalid"]}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {items?.length > 0 ? (
            items?.map((item) => {
              return (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              );
            })
          ) : (
            <SelectItem disabled value="null">
              No option to select
            </SelectItem>
          )}
        </SelectContent>
      </Select>
    </>
  );
};
